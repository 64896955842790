export function findDuplicatedValues(arr) {
    const checkDuplicates = arr
        .map((item) => JSON.stringify(item.value))
        .reduce((acc, current) => {
        if (acc[current] === undefined) {
            acc[current] = 1;
        }
        else {
            acc[current] += 1;
        }
        return acc;
    }, {});
    return Object.keys(checkDuplicates).filter((field) => checkDuplicates[field] > 1);
}
